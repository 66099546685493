<template>
    <div id="contactMain" class="bg-card p-3 link bottom-spacer d-flex flex-column align-items-center">
        <h2>Contact Us</h2>
        <p>Please send inquiries to bandyanas@gmail.com.</p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>