<template>
  <div
    id="navMain"
    class="
      d-flex
      flex-row
      justify-content-around
      bg-card
      flex-grow-1
      bottom-spacer
    "
    :class="{ sticky: isSticky }"
  >
    <div class="navDiv flex-grow-1" v-show="isSticky">
      <div class="p-3 link pointer" @click="goToDiv('mainBody')">
        <h2 class="quicksand navtab">Top</h2>
      </div>
    </div>
    <div class="navDiv flex-grow-1" v-for="nav in links" :key="nav.name">
      <div class="p-3 link pointer" @click="goToDiv(nav.link)">
        <h2 class="quicksand navtab">{{ nav.name }}</h2>
      </div>
    </div>
    <div class="navDiv flex-grow-1 d-flex justify-content-center align-items-center link pointer" @click="goToFacebook">
      <font-awesome-icon icon="fa-brands fa-facebook-square" class="icon" />
    </div>
    <div class="navDiv flex-grow-1 d-flex justify-content-center align-items-center link pointer" @click="goToInsta">
      <font-awesome-icon icon="fa-brands fa-instagram" class="icon" />
    </div>
  </div>
  <div v-show="isSticky" class="spacer">&nbsp;</div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { name: "Shop", link: "shopMainDiv" },
        { name: "Happy Customers", link: "slidesMain" },
        { name: "About", link: "aboutMain" },
        { name: "Contact", link: "contactMain" }
      ],
      isSticky: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.setSticky);
  },
  methods: {
    goToInsta() {
      this.goToWebsite("https://www.instagram.com/bandyanas/");
    },
    goToFacebook() {
      this.goToWebsite("https://www.facebook.com/bandyanas");
    },
    goToWebsite(url) {
      window.open(url, "_blank").focus();
    },
    goToDiv(id, margin = 100) {
      const e = document.getElementById(id);
      const dims = e.getBoundingClientRect();
      window.scrollTo(window.scrollX, dims.top - margin + window.scrollY);
    },
    getNavOffset() {
      let w = window.innerWidth;
      if (w > 1200) return 600;
      else if (w > 800) return 500;
      else if (w > 550) return 400;
      else return 200;
    },
    setSticky() {
      this.isSticky = window.pageYOffset >= this.getNavOffset();
    },
  },
};
</script>

<style>
.pointer:hover {
  cursor: pointer;
}
.navDiv {
  transition: background-color 0.3s linear;
}
.navDiv:hover {
  background-color: rgba(13, 18, 20, 0.699);
}
#navMain {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.quicksand {
  font-family: Quicksand, Avenir, Helvetica, Arial, sans-serif;
}
.navtab {
  font-size: 36px;
}
.icon {
  font-size: 52px;
}
.sticky {
  position: fixed;
  top: 0;
  max-width: 1200px;
  background-color: rgb(38, 52, 58) !important;
  z-index: 1;
}
.spacer {
  margin-bottom: 120px;
}
@media only screen and (max-width: 1200px) {
  .banner-image {
    height: 500px;
  }
  .navtab {
    font-size: 28px;
  }
  .spacer {
    margin-bottom: 110px;
  }
}
@media only screen and (max-width: 800px) {
  .banner-image {
    height: 400px;
  }
  .navtab {
    font-size: 20px;
  }
  .icon {
    font-size: 34px;
  }
  .spacer {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 550px) {
  .banner-image {
    height: 200px;
  }
  .navtab {
    font-size: 14px;
  }
  .icon {
    font-size: 26px;
  }
  .spacer {
    margin-bottom: 90px;
  }
}
</style>