<template>
  <div id="aboutMain" class="bg-card link p-3 bottom-spacer">
    <h2>About</h2>
    <div class="m-3">
      <p class="text-light">Bandyanas was created from a love for dogs and crafting.</p>

      <p class="text-light">
        My name is Rachel Bandy, and I am the owner and founder of Bandyanas. My
        husband bought me a Cricut for Christmas of 2018, and the first items I
        made were personalized bandanas for our two golden retrievers, Ellie and
        Chester. After posting pictures of my dogs wearing their bandanas to my
        personal social media accounts, I received numerous requests from
        friends and family to create bandanas for their pets.
      </p>

      <p class="text-light">
        With the end of the school year approaching (I am a high school
        chemistry teacher by day), my husband suggested that I start a small
        business. Naming my new business initially seemed like a challenge, but
        I eventually settled on Bandyanas. I love our name as it is a
        combination of my last name, Bandy, and the product that we sell,
        bandanas. I made a handful of bandanas in a few summer prints and
        launched my Etsy site in May of 2019. I only sold to friends and family
        during the first few months but can vividly remember the day that I
        received an order from someone that I did not know. With that sale, I
        felt like I just might have a chance at pursuing this little dream of
        mine.
      </p>

      <p class="text-light">
        I have expanded my inventory over the past three years to include tie
        on, reversible bandanas in three sizes, puppy/cat sized bandanas that
        slide onto collars, bow ties, collars, leashes, and matching scrunchies
        for humans.
      </p>

      <p class="text-light">
        Ellie and Chester oversee the bandana making process by sleeping at my
        feet as I am working. They take their bandana modeling duties very
        seriously and are featured often on our social media accounts.
      </p>

      <p class="text-light">
        My favorite part of this business is meeting other people who love their
        dogs just as much as I love mine. It brings me so much joy. Thank you
        for being a part of this journey! It has been my biggest surprise and
        the wildest ride.
      </p>

      <img id="aboutPic" alt="The Bandy family" src="https://www.dropbox.com/s/n46ha6lbvnxoehj/62DA1B14-968D-4000-B7CB-097C0D126280-65032-00001C6A70221899.jpg?raw=1" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
p {
  font-size: 20px;
  text-align: justify;
  font-family: Tajawal, Avenir, Helvetica, Arial, sans-serif;
}

#aboutPic {
  width: 100%;
}
</style>