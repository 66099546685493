<template>
  <div class="d-flex flex-row fixed-top banner">
    <div v-for="(img, i) in slides.winter" :key="i" ref="bannerDiv" v-show="showBanner">
      <img class="banner-image d-block" :id="i" :src="img" alt="banner image" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      slides: {
        fall: [
          "https://www.dropbox.com/scl/fi/xgmgl98vfg5q31unn69wt/0H0A8607-sharpen-1.jpg?rlkey=e5oip9i7yor74znxer1tydrjl&raw=1",
          "https://www.dropbox.com/scl/fi/z53bynywlir54q3t9gdjq/763542D0-C83B-43E4-914D-219BD6E74B21.jpg?rlkey=k7gyvt9fjq2e5ttnafq8fm1fp&raw=1",
          "https://www.dropbox.com/scl/fi/52tlcqljv65gprpcmgb71/20221007_134928-01.jpg?rlkey=pjfnswzjxob36olagr3s07ixn&raw=1",
          "https://www.dropbox.com/scl/fi/d8ne3h86hpy1dl4vzs91q/Bandyanas-1.jpg?rlkey=pgbl3h03191kzm8inbspitgrv&raw=1",
          "https://www.dropbox.com/scl/fi/gawhf3a8x32sangs8eb8a/D5DD426E-E18F-486C-8060-683E5294F6FD.jpg?rlkey=2vyn24v40ob8ai8g0iae337wb&raw=1",
          "https://www.dropbox.com/scl/fi/bn2idepyocrn7dzk274z8/DVNI3119.jpg?rlkey=umlbp3uwv4o6pucvnz6az1dhb&raw=1",
          "https://www.dropbox.com/scl/fi/bans7b3xy292tfhl08wc6/IMG_0801.jpg?rlkey=rfk6dfwitnkyp94zppmvzjpta&raw=1",
          "https://www.dropbox.com/scl/fi/u0u57xqiwq4dlnf1m3jqp/IMG_1096.jpg?rlkey=r4ae434vyen26n9zb49j371bo&raw=1",
          "https://www.dropbox.com/scl/fi/wad9geea271zjyr4zhwav/IMG_1197.jpg?rlkey=yjlpnpezfdhjhd45veaoagr00&raw=1",
          "https://www.dropbox.com/scl/fi/dd4hnn02lipt999h0vxbn/IMG_1347.jpg?rlkey=no9ivt7sb6egqxivpkfe2dr67&raw=1",
          "https://www.dropbox.com/scl/fi/xby02qwk2zynglb4i8y4i/IMG_5271.jpg?rlkey=uewmvuhxb1cyrb80lzh4urix3&raw=1",          
        ],
        winter: [
          "https://uc3cd3a3da731ef28d832298d193.previews.dropboxusercontent.com/p/thumb/ACJCb5vtDLOikZwWZZdIhl8mFqWeuf45ok5DzuytNoBMlF6vyVOXJkT8r81iOJGPxK8QIdS79KyLqV9aLaYkXN58Hw1XF5Ae2LxDhB3rKEhZoSftfOyiNLCuaF9y9nBbUmF096gO9-1qrZswkZEX7XSnW9MFK162ZnqH9zAduPnndXpsFcrQWB2CNjTGhI44WLwUXGxIUEF4bwtIjxB6yj6osLOd6JVMMVg-TrLb9vH6EhH9eaSuB7RIFPiXgaj4wSaoLVRaLbvT64bBWZe6fbyFxggZBdr9OXhqpDtW1OwsKcXLksPBJYVQReTSmZJr5tys3ipcDbwULJq-S7WNQe-TzZ5s9rQp5sPSJBEgoMIwNQPzkPkb2T3wzSE7ubfA2ho/p.jpeg",
          "https://uca2921632606c4293837c818fe4.previews.dropboxusercontent.com/p/thumb/ACKSkP_1NYPlwQQ7OrU43Tn_IQO5G8FB6_QYjq9PuosUNybo9dGyo54EgFpvULQ215x_utsr66jmOafB94tgI1pdoviGf2KUuxZLdkwf-RiUwmhhhtVookhV_xMIy5vA4xIl6ePU7dyCkomdCZzBZ6h_B5DcdkKTZYDgW5IEwkVpazr6VLc3lhHHzQEmvr-cdyXGg2kJgo4uWGxmTojqDMS7mxGvjx2lv4wsfzQ27QWhFHDqH2CEoAdKhj1BY2VpRtbbRsWKlq9fiuVXUi6YXQTQsOH3QYZdyU8c4iRnJ52YnYk2HVIkmzhiQuCudL-kg2T42zUqnWvHKClE12mfYhDBPcmoO7mT3i8sFwaWF5FJfPDVQZO29lHdhu_9qcMSAZU/p.jpeg",
          "https://ucfd8cf97964625070fb1ea2d617.previews.dropboxusercontent.com/p/thumb/ACJksvZIha1wnwkbe58fPMVlBz6gbGgi4H7nyQ_6KNijRSbd2ZVPSDY86NY0Nnw6xg2PEktuOlr6hQO3OgYFuiPL3zMqYhtrKziuAU1kYbKJpdHOb4X2T_mWY_p7jmkZzUWl79NjZyopfvfkJH5grE15vxB6DnkX8J5oEjOw4nHAWF3rcErtH-bXwIooQwT6G--6wh5aIh8oIwN-XrlGUmr-HUlZ_DvGsz-67PmxkmRrJpumPxZav5zRjPlg8k_dqvBSBJefOIEO88vM672YqIoK1fqM4Ph5MkbwGd1PGxAPWbHQKV9iC4p_kjGsiBU7wIXrF2fi7stUq6MG1OH7fNIuglhgI-pvenXuEuQo9x5Pq_cCDm6dcq2t3exY8MhqmFU/p.jpeg",
          "https://ucc580c0741c29b62768922c1d20.previews.dropboxusercontent.com/p/thumb/ACLaHyuF3gJd4j7YSIQQ8LyMwe-H4uKG2rwnlQDa7NmWv5vKy1vpAkPelWDsPX1EeS9nh2pNjLz2eIXBRSj1jnsEjj_ubKdo4NzJhD5muhC0Aulnn-hV6JiH75cczPgoAENOIHn_OZAxTK7qsHk9hPYb4nVuKgtIGXAHlJv47-Zjt34-qN6hLipfHUP1cCr1DjNduExPktwXwglxnsCC2fg-OaqHSwGd7NYVrNswX3hiO9aq29zwCCBfUqTSkEdGo8mwU-1nqVmmuXrRo09IPVeQDbymHgu5a7uJuJANwwrb4e_tr0B5LPqOSdy9_ZOCtlkOtA4i7_0J2JcpHeqeuk9FH2XMYhKd4X8B6IMS4eXWoanRFnjlDgOMUXFYGhF8yU8/p.jpeg",
          "https://uc61a2405bb8b0e5ae73a37eef79.previews.dropboxusercontent.com/p/thumb/ACIwXnJNqv3tUP1SOYt6LrTtSwoRs-wuWr-VhIYgXzN-X62CDfVlBr9GV5hroCO6-jeDT0kOin9l5gmtkvlXlYqKoDiqGruqmkn4jasMn-smQ6WGBT2jlQa6jGT5KHdqOnEGuCiKiJXX7CCmi--S2XFJgCdvb7Y767DCOoQJPmi0jHrOENVVjf1rcEZLlOgt6uV7_Bjq9ur_y22EuIyKEkMpGLG2Z8Se-8tdkl-ij5AQem8XjNubohORJCc-WKP9xHCqFAN8kYRy5mUKXygrHSkMAkoOA1e9JI1GzKvb0OWPwDk1Yzq5yWvhklC2roQI9vUmXKEXZdmvixN1P8LNFAbHV_6QJUvBRq_RG2G4gvzFrQ5otJqMeLCdTQfelHgPcGc/p.jpeg",
          "https://ucdcb3390c4ff7202278b38c5bf2.previews.dropboxusercontent.com/p/thumb/ACJ9qP-NhRkAsyofmxTuppfCUKvENt9ggljxiauie4SCeFveyx0-qTo7rX3q2fI8AOAFKprNKNPxHoDi8HrK73hrwdRLeHcQOvfkQ2x2seYlAbioFWSIrI3lJhfY2EtH5iyKcBSa8eh3crBBAVA5sqr_0rY5ZLbL5sGuxLkG23CDbJg76LmYT2LNfvZNA1X8Kq4XXWKE-lr4LT8KN0V4Qc-sWDlQXKlsOtRbeaUgC1boPbwMrcsJV70Xu-XuksxdGgX0zP56zwD6rlJQ2Sd-j2mr3J4rqqmwwxSVZtnqAocclc_GmE74azCmVx-YkOXoNrLsy7zMEIQlb6l18myJDTdn0zf2mLqpj8S0mz2B8hzfzanNnQ-vJzPJO1c2zBIbVQA/p.jpeg",
          "https://ucb62fe1e8c4d5977c4e3bab58c6.previews.dropboxusercontent.com/p/thumb/ACId1I3MWQnKbiydmXnl0JtQZmIoCthKFc9M6UcK3qN0ei_1y1NV-A2fK90ftOi9L02YGDLoTKVNOfuQljFZ42QjGH_J8J_glHBrbxTm4nzPuQQlo8GEb5OBSUrHtcS9c9GMT1i2F6MTOdDkuWwX2bzVbN9-GBE7IFyLT3azrGo0NqcfUlvk-GGUkTE_xUq9kRMh8LKegULzkMyEyMJV0BskSa7rm9SvNQX6SXE0K9OD74jyv6U8uoyIUb4SUttYNL7colr-YwI_GSvx79Yk8jec2_3_CjgrPydOkgwUUC9fQCpUKG--6C1rs4PVzNt8x2Fex5iM4_QzukxqdI4yhaIk5Ma73fq1ChLlAT0Hg8I2Brci9u0S9o9xPYjhn0pZbbo/p.jpeg",
          "https://uc3e46401f497b34e2d64c0f6862.previews.dropboxusercontent.com/p/thumb/ACLdkA6W_Q9lKDhiCaaa8bB4YxXj9X4DCJtY67GRcZB9DMpidmC1CxuLU7yYoiw9EZs9WB71K7HzfbqCG_SnAwe5ySi0ETXEFyv840qHoYdGR5Rdy7LrQ-a9PsiAA6QcOakJz9ACnQd2vxWHvbmU6yZydRe9DeKGyZz9GfRyx4ebbEVhEl62TAspIcUKX5xVtOoJzFFZsxpz68784NeWSkauwgdQfsUrPviUE-B2FsYU3ps34Nu9bL-aLXPPMgXtCSfYVOHo-B6uAu66MGzPFaVJ178RQ8PGEvWCEfeGNu0xOAmjdueSbTnRqau_-Nq1NPNX6iqP7VVNkvi9N2fmRk74ZBTQJ2F2PJkFGFDdYGv38kL1qFINyYjWe-ZSpabAvO7s83WLqzPhV-51UmmBFPrCOUyYpWXpIfXZIq_BIrW1tmC6g1nCa4TxJ6Y5N2kGvTQ/p.jpeg",
          "https://uc7f2436798ab1ffeef856bfb6fd.previews.dropboxusercontent.com/p/thumb/ACLdk4ww68iWYUYD-4DUAGhJyBx5TFXTkIxsfX4Mr89bY8petgqsB1MznBBT_EXucpwlbKswC7Pqpg7GQrMqQcV-h1m4H11ysv_ajceu22H-dm7WnKCvnjEY8RD4SfGxkXOIS0jht505YRla1VtofGVnVccHgpCZ3cWFjrihdvnNEG74HOQpJCwBbw_iJ-LkzeG-uocK_BXZSVzd2Fc45J0cAl4Q39nvvIwnpSVLYhWmmHDb-bSlv2mC1waZ-e1PNCRr5gUul588ttN2CfpHPOFdGEuJVwwoApFVcSc6yPg7mz-u18WFv3JmKF5rDxLHrZ6et_a1hYxbg8mp0M05D72FWYLHf7H1xsluuTJ55aajPM2G_LI0QhNZGel0FFLxVtg/p.jpeg",
          "https://ucef3863ef1cd7c1ca77ddf7db32.previews.dropboxusercontent.com/p/thumb/ACLgKRPK4UJElZrddU0tDyaoW9eBvDUCxq60_7cbqiXK_ZeVFyr9qx6Ph-x6AV2kAqe8rot1fMRpJF_BX7q-1tGEezFEqPZFA32EwXyK0OlTcj1VxaCG4Cd-W6zp5dCypIvJyIpGuVFDwg7Lte-N4h_pNlgpNEMLKW0nfSBVgLII7_G_8hshgPyysm8Wc_2xgT9HKWSfBE2MTZ_v0yVV7Nqg7Cu-TUYCUC9p4JdfBEVz3Pp07PSmYKolv-TaFvPQtd0qDDGg9COXKwcVvnKTC1Jx8tXd8Vlzp-jIS9RuEfjKqUpkVADqOBbJQh38c9Y7GtQmPkhwlYQC-jKP_1cl5VoWAiAsZ1OHkuviWXxbFRoNPlX0uqDdYwS4DBXbpPQUD5I/p.jpeg",
          "https://uc56ce13ad8f9b7ad7b24e98c34f.previews.dropboxusercontent.com/p/thumb/ACIZG7Jg4Mg41MCpiZ2oLz743d1mcbz7hZxR0s5SshswnZMdKTMQPbtyff0Ae0hBhpe8r3KWavvfwPPbl4T9I6WNDGdquzU1b2KMtpy8yYz0TNTX0PZNKc8UrcrQc4SNAGeCN5pVByT8tgd0BXWXPWp5kGEHYuJUq9L0ebeDRqys19Sb4ly1FemO3HolGZEoLFtNkds-VjRg5JMMrMOXi9386rAZp3JMR8aP-yD4hDr1klYkcs0baXRerYaH0ds6gibPq06T3salzD3lxkwafzjGw-nFTLLmni85l5aCGF9e4vW09NKjESVDFJJHDmyE0pivjvzHSyBgHwkc89yM0x5O02AOsFRL3COthAhtKr1u3VZRq4eWBO0BnN4foSdWzY8/p.jpeg",
          "https://uc3320189da3f269383b8b6ba632.previews.dropboxusercontent.com/p/thumb/ACI_Cy3BfV_I9bMyETjsye7v2uvUVIw6bTFCnkHWJpxAARBcIx98c4SapeCFMGBXBNPUd6-yLZ-4VbYtJzh1q76nvAzpvQaJnVJT4WiZy3xKAuhIqlGxfhwEH6UWZjN6yXhp4Q78JjgH8SCq8whkskbLv4rApGi2J42swV98Zqg6uSyCQW99_VpHe9YzViXskNiKUO0OoPSPRYR2SnPuiaYIVDcPNoaxkF7zlF4b8nGcyaP7iebvFH9yFo6GGFmUxjlS7iDI-Pq3fQw1VUTnKsFv_lZCt6rnR8DZbIa66L8F3O3Y7pN2QF5LhJjq7cqQk6VFodlhTqGmVW4UpLg0qynrnE5cIzZ54FLn_5qeZmxgpiK5Sw_1WspElHcLkWKFQLM/p.jpeg",
        ],
      },
      showBanner: false,
    };
  },
  methods: {
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      this.showBanner = true;
    },
  },
  created() {
    this.shuffleArray(this.slides);
  },
};
</script>

<style>
.banner-image {
  height: 600px;
}
.banner {
  z-index: -1;
}
@media only screen and (max-width: 1200px) {
  .banner-image {
    height: 500px;
  }
}
@media only screen and (max-width: 800px) {
  .banner-image {
    height: 400px;
  }
}
@media only screen and (max-width: 550px) {
  .banner-image {
    height: 200px;
  }
}
</style>