<template>
  <div class="title-div d-flex flex-column align-items-center">
    <h1 class="title">Bandyanas</h1>
    <div class="d-flex justify-content-center" id="subtitle-box">
      <h3 class="subtitle">Handmade pet&nbsp;&nbsp;</h3>
      <h3 class="subtitle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3>
      <h3 class="subtitle">accessories&nbsp;</h3>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.title-div {
  background-color: rgba(0, 0, 0, 0.425);
  color: rgb(255, 255, 255);
  height: 600px;
}
.title {
  font-family: torshavnregular;
  font-size: 300px;
  padding-top: 100px;
  padding-bottom: 90px;
}
#subtitle-box {
  margin-top: -130px;
}
.subtitle {
  font-size: 60px;
  font-family: Quicksand;
  font-weight: 400;
}
#logo {
  height: 300px;
}
@media only screen and (max-width: 1200px) {
  .title {
    font-size: 220px;
    padding-top: 100px;
  }
  #subtitle-box {
    margin-top: -120px;
  }
  .subtitle {
    font-size: 42px;
  }
  .title-div {
  height: 500px;
  }
  #logo {
    height: 200px;
  }
}
@media only screen and (max-width: 800px) {
  .title {
    font-size: 150px;
    padding-top: 100px;
  }
  #subtitle-box {
    margin-top: -115px;
  }
  .subtitle {
    font-size: 30px;
  }
  .title-div {
  height: 400px;
  }
  #logo {
    height: 100px;
  }
}
@media only screen and (max-width: 550px) {
  .title {
    font-size: 100px;
    padding-top: 32px;
  }
  #subtitle-box {
    margin-top: -108px;
  }
  .subtitle {
    font-size: 20px
  }
  .title-div {
  height: 200px;
  }
  #logo {
    height: 0;
  }
}
</style>