import { createApp } from 'vue';
import App from './App.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import VueAxios from 'vue-axios';
import PrimeVue from 'primevue/config';
import Galleria from 'primevue/galleria';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

library.add(faFacebookSquare);
library.add(faInstagram);

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('Galleria', Galleria)
    .use(VueAxios, axios)
    .use(PrimeVue)
    .mount('#app');