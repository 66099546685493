<template>
  <div id="shopMainDiv" class="bottom-spacer">
    <a href="https://www.etsy.com/shop/bandyanas" target="_blank">
      <div
        id="shopOnline"
        class="sideBySide bg-card p-4 link pointer bg-change bottom-spacer"
      >
        <h1 class="text-center">Shop Online</h1>
        <img
          class="shopItem"
          src="https://www.dropbox.com/s/y9kovsx0e9cccu3/etsy-screenshot-2.png?raw=1"
          alt="etsy shop"
        />
      </div>
    </a>

    <div id="shopInPerson" class="bg-card p-4 link bottom-spacer">
      <h2>Market Schedule</h2>
      <div>
        <table class="table table-sm text-light">
          <tbody>
            <tr>
              <td class="text-start col-md-2"></td>
              <td class="text-start col-md-4"></td>
              <td class="text-start col-md-6"></td>
            </tr>
            <tr v-for="event in events" :key="event.id">
              <td class="text-start">
                {{
                  event.endDate !== event.startDate
                    ? `${event.startDate} - ${event.endDate}`
                    : event.startDate
                }}
              </td>
              <td class="text-start">
                <a :href="event.link" target="_blank">{{ event.name }}</a>
              </td>
              <td class="text-start">{{ event.location }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      id="shopBM"
      class="
        d-flex
        flex-row
        justify-content-around
        align-items-center
        bg-card
        p-4
        link
      "
    >
      <h2>Shop brick and mortar</h2>
      <div class="d-flex flex-column">
        <div v-for="(shop, i) in shops" :key="i">
          <h3>
            <a :href="shop.link" class="link pointer" target="_blank">{{
              shop.name
            }}</a>
          </h3>
          <p class="text-center text-light">{{ shop.address }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import googleCalendarApi from "@/services/GoogleCalendarApi.js";

export default {
  data() {
    return {
      events: [],
      response: {},
      date: "",
      shops: [
        {
          name: "Market on Main",
          address: "103 W Main St, Bridgeport, WV 26330",
          link: "https://www.marketonmainwv.com/",
        },
        {
          name: "Wit & Whimzy",
          address: "152 Front St, Marietta, OH 45750",
          link: "https://g.page/witnwhimzy?share",
        },
        {
          name: "Artisan Center Downtown",
          address: "1400 Main St, Wheeling, WV 26003",
          link: "https://wheelingheritage.org/",
        },
        {
          name: "Artisan Center at Oglebay: Carriage House",
          address: "83 Mansion Dr, Wheeling, WV 26003",
          link: "https://wheelingheritage.org/",
        },
      ],
    };
  },
  async mounted() {
    this.date = this.getTodaysDate();
    this.response = await googleCalendarApi.getCalendarData(this.date);
    this.setEvents(this.response.data);
    this.shops.sort((a, b) => (a.name > b.name ? 1 : -1));
  },
  methods: {
    getTodaysDate() {
      return new Date().toISOString().substring(0, 10);
    },
    setEvents(resp) {
      let i = 0;
      const linkRegex = /.*?"(.*?)".*/;
      for (const item of resp.items) {
        const match = linkRegex.exec(item.description);
        const link = match?.length > 1 ? match[1] : null;

        let event = {
          id: i,
          name: item.summary,
          startDate: item.start.date || item.start.dateTime,
          endDate: item.end.date || item.start.dateTime,
          location: item.location,
          link: link,
        };
        event.startDate = this.formatDate(event.startDate);
        event.endDate = this.formatDate(event.endDate);
        this.events.push(event);
        i++;
      }
      this.events.sort((a, b) => (a.startDate < b.startDate ? -1 : 1));
    },
    formatDate(date) {
      if (date === null || date === undefined) return;
      return `${date.substring(5, 7)}/${date.substring(8, 10)}/${date.substring(
        0,
        4
      )}`;
    },
    openLink(link) {
      if (!link) return;
      window.open(link, "_blank", "noopener");
    },
  },
};
</script>

<style>
#shopMainDiv {
  display: flex;
  flex-direction: column;
  row-gap: 0;
}
h2 {
  font-family: Quicksand, Avenir, Helvetica, Arial, sans-serif;
}
.sideBySide {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.shopItem {
  width: 70%;
}
</style>