<template>
  <Main></Main>
</template>

<script>
import Main from "./components/Main.vue";

export default {
  name: "App",
  components: {
    Main,
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #c6cdd6;
}
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background-color: rgb(97, 99, 107);
}
.bg-card {
  background-color: rgba(38, 52, 58, 0.911);
  border-radius: 4px;
  width: 100%;
}
.link {
  color: rgb(155, 204, 214);
  font-weight: 100;
  transition: color 0.3s linear;
}
.link:hover {
  color: rgb(160, 238, 252);
}
.bg-change {
  transition: background-color 0.3s linear;
}
.bg-change:hover {
  background-color: rgba(23, 33, 37, 0.781);
}
a {
  text-decoration: none;
}
.bottom-spacer {
  margin-bottom: 60px !important;
}
@font-face {
    font-family: 'torshavnregular';
    src: url('assets/webfontkit-20220731-154952/torshavn-regular-webfont.woff2') format('woff2'),
         url('assets/webfontkit-20220731-154952/torshavn-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
</style>