<template>
  <div id="announcement-div" class="bottom-spacer p-4 m-auto" v-show="announcement !== null">
    <h2 class="announcement">{{announcement}}</h2>
  </div>
</template>

<script>
export default {
  data() {
    return {
      announcement: null,
    }
  }
}
</script>

<style>
#announcement-div {
    background-color: rgba(255, 255, 255, 0.733);
    color: rgb(54, 54, 54);
    width: 80%;
    border-radius: 20px;
}
.announcement {
    font-weight: bold;
    font-style: italic;
}
</style>