<template>
  <div class="bg-card p-3 d-flex justify-content-center bottom-spacer" id="slidesMain">
    <Galleria
      :value="slides"
      containerStyle="width: 100%"
      :numVisible=5
      :responsiveOptions="responsiveOptions"
    >
      <template #item="slotProps">
        <img
          class="galleria-image"
          :src="slotProps.item.image"
          :alt="slotProps.item.alt"
        />
      </template>
      <template #thumbnail="slotProps" id="thumbs">
        <img
          class="galleria-thumb"
          :src="slotProps.item.image"
          :alt="slotProps.item.alt"
        />
      </template>
    </Galleria>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      slides: [
        { image: "https://www.dropbox.com/s/2bncgut260z7yea/freekisses.jpg?raw=1", alt: "Free Kisses" },
        { image: "https://www.dropbox.com/s/df1n1fwoqolx3f7/cat2.jpg?raw=1", alt: "Cat wearing a bandana" },
        { image: "https://www.dropbox.com/s/81m1qzqomv1409q/nosy2.jpg?raw=1", alt: "Cow print" },
        { image: "https://www.dropbox.com/s/dyxy9xkif4xl3jg/collector.jpg?raw=1", alt: "Bandana collector" },
        { image: "https://www.dropbox.com/s/2spb5chw2iqa97v/goat.jpg?raw=1", alt: "Goat wearing a bandana" },
        { image: "https://www.dropbox.com/s/5dj2wp5elzmjhpe/brooke.jpg?raw=1", alt: "I <3 socks" },
        { image: "https://www.dropbox.com/s/cx3dhgls23v67vs/cat.jpg?raw=1", alt: "Cat wearing a bandana" },
        { image: "https://www.dropbox.com/s/z8znt4rgvldrn3m/valentine.jpg?raw=1", alt: "Pink Cheetah" },
        { image: "https://www.dropbox.com/s/clpedl9if296sor/easter.jpg?raw=1", alt: "Easter" },
        { image: "https://www.dropbox.com/s/ebeyxryt19ixxbd/ellie.jpg?raw=1", alt: "Ellie" },
        { image: "https://www.dropbox.com/s/3nu1mf8027kykn5/beagle.jpg?raw=1", alt: "White paw" },
        { image: "https://www.dropbox.com/s/35nx9a62qak5tip/cat3.jpg?raw=1", alt: "Cat wearing a bandana" },
        { image: "https://www.dropbox.com/s/u6e5s44kshmmyxg/floral.jpg?raw=1", alt: "Floral" },
        { image: "https://www.dropbox.com/s/8wgd7s0q3xpe8j7/nosy.jpg?raw=1", alt: "Floral" },
        { image: "https://www.dropbox.com/s/eoeopgy2hglawu5/littlebrooke.jpg?raw=1", alt: "Puppy plaid" },
        { image: "https://www.dropbox.com/s/t1778xwsi0rmish/christmas.jpg?raw=1", alt: "Naughty and Nice" },
      ],
      responsiveOptions: [
				{
                    breakpoint: '1024px',
                    numVisible: 7
                },
                {
                    breakpoint: '768px',
                    numVisible: 5
                },
                {
                    breakpoint: '560px',
                    numVisible: 3
                }
			]
    };
  },
  mounted() {
    this.shuffle(this.slides);
  },
  methods: {
    shuffle(array) {
      let currentIndex = array.length,  randomIndex;
      while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      return array;
    }
  }
};
</script>

<style>
.galleria-image {
  height: 800px;
  display: block;
}
.galleria-thumb {
  height: 100px;
}
@media only screen and (max-width: 1200px) {
  .galleria-image {
    height: 600px;
  }
  .galleria-thumb {
    height: 80px;
  }
}
@media only screen and (max-width: 900px) {
  .galleria-image {
    height: 400px;
  }
  .galleria-thumb {
    height: 60px;
  }
}
@media only screen and (max-width: 600px) {
  .galleria-image {
    height: 250px;
  }
  .galleria-thumb {
    height: 40px;
  }
}
</style>