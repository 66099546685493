<template>
  <div id="mainBody" :class="{sticky: sticky}">
    <Banner></Banner>
    <Title></Title>
    <Nav></Nav>
    <Announcements></Announcements>
    <Shop></Shop>
    <HappyCustomers></HappyCustomers>
    <About></About>
    <Contact></Contact>
  </div>
</template>

<script>
import About from "@/components/About.vue";
import Shop from "@/components/Shop.vue";
import Title from "@/components/Title.vue";
import Nav from "@/components/Nav.vue";
import HappyCustomers from "@/components/HappyCustomers.vue";
import Banner from "@/components/Banner.vue";
import Announcements from "@/components/Announcements.vue"
import Contact from "./Contact.vue";

export default {
  components: {
    About,
    Shop,
    Title,
    Nav,
    HappyCustomers,
    Banner,
    Announcements,
    Contact
},
  data() {
    return {
      sticky: false
    }
  }
};
</script>

<style>
#mainBody {
  margin: auto;
  max-width: 1200px;
}
</style>